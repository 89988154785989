@media (min-width: 768px) and (max-width: 1199px) {
  .custom-h4 {
    letter-spacing: 1.5px;
    font-size: 20px;
    line-height: 24px;
  }
}

.accordion button p {
  line-height: 100%;
}

.accordion.line-secondary button {
  border-bottom: 1px solid #1d4045;
}

.accordion-row {
  color: #1d4045;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  padding: 15px 0 10px;
  font-weight: bold;
  transition-duration: 1s;
}

.accordion-arrow {
  width: 25px;
  transition-duration: 1.5s;
  display: inline;
  position: absolute;
  right: 0;
}

.accordion-arrow.arrow-inner {
  right: 45px;
}

.panel.slide {
  max-height: 1500px;
  transition: max-height 1.5s ease-in;
}

.panel {
  max-height: 0;
  background-color: #fff;
  padding: 0 18px;
  transition: max-height 1.5s ease-out;
  overflow: hidden;
}

#mobile-footer-accordion, #tablet-footer-accordion-pt1, #tablet-footer-accordion-pt2 {
  margin-bottom: 20px;
}

#mobile-footer-accordion .accordion .card, #tablet-footer-accordion-pt1 .accordion .card, #tablet-footer-accordion-pt2 .accordion .card {
  background-color: #1d4045;
  border-top: none;
  border-bottom: 1px solid gray;
}

#mobile-footer-accordion .accordion .card .card-content div ul, #tablet-footer-accordion-pt1 .accordion .card .card-content div ul, #tablet-footer-accordion-pt2 .accordion .card .card-content div ul {
  margin: 0;
}

#mobile-footer-accordion .accordion .card .card-content div ul li, #tablet-footer-accordion-pt1 .accordion .card .card-content div ul li, #tablet-footer-accordion-pt2 .accordion .card .card-content div ul li {
  margin-bottom: 12px;
}

#mobile-footer-accordion .accordion .card .card-content div ul li a, #tablet-footer-accordion-pt1 .accordion .card .card-content div ul li a, #tablet-footer-accordion-pt2 .accordion .card .card-content div ul li a {
  color: #fff;
}

#mobile-footer-accordion .accordion .card .card-header .card-header-icon svg > *, #tablet-footer-accordion-pt1 .accordion .card .card-header .card-header-icon svg > *, #tablet-footer-accordion-pt2 .accordion .card .card-header .card-header-icon svg > * {
  fill: #fff !important;
}

#mobile-footer-accordion .accordion .card .card-active .card-header .card-header-icon svg > *, #tablet-footer-accordion-pt1 .accordion .card .card-active .card-header .card-header-icon svg > *, #tablet-footer-accordion-pt2 .accordion .card .card-active .card-header .card-header-icon svg > * {
  fill: #ffaa2b !important;
}

#mobile-footer-accordion .panel.slide, #tablet-footer-accordion-pt1 .panel.slide, #tablet-footer-accordion-pt2 .panel.slide {
  max-height: 500px;
  transition: all 1s ease-in;
}

#mobile-footer-accordion .panel, #tablet-footer-accordion-pt1 .panel, #tablet-footer-accordion-pt2 .panel {
  max-height: 0;
  -o-transition: all 1s ease-in-out;
  background-color: #1d4045;
  padding: 0;
  transition: all 1s ease-in-out;
  overflow: hidden;
}

#mobile-footer-accordion .panel ul, #tablet-footer-accordion-pt1 .panel ul, #tablet-footer-accordion-pt2 .panel ul {
  margin-top: 20px;
  margin-bottom: 15px;
}

#mobile-footer-accordion .panel ul li a, #tablet-footer-accordion-pt1 .panel ul li a, #tablet-footer-accordion-pt2 .panel ul li a {
  color: #fff;
  text-transform: capitalize;
  letter-spacing: .5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  #mobile-footer-accordion .panel ul li a, #tablet-footer-accordion-pt1 .panel ul li a, #tablet-footer-accordion-pt2 .panel ul li a {
    font-size: 18px;
  }
}

#mobile-footer-accordion .panel ul li a:hover, #tablet-footer-accordion-pt1 .panel ul li a:hover, #tablet-footer-accordion-pt2 .panel ul li a:hover {
  transform: translateX(10px);
}

#mobile-footer-accordion .panel ul li a:hover, #mobile-footer-accordion .panel ul li a:focus, #mobile-footer-accordion .panel ul li a:active, #mobile-footer-accordion .panel ul li a.active, #tablet-footer-accordion-pt1 .panel ul li a:hover, #tablet-footer-accordion-pt1 .panel ul li a:focus, #tablet-footer-accordion-pt1 .panel ul li a:active, #tablet-footer-accordion-pt1 .panel ul li a.active, #tablet-footer-accordion-pt2 .panel ul li a:hover, #tablet-footer-accordion-pt2 .panel ul li a:focus, #tablet-footer-accordion-pt2 .panel ul li a:active, #tablet-footer-accordion-pt2 .panel ul li a.active {
  color: #ffaa2b;
}

#mobile-footer-accordion .accordion-row, #tablet-footer-accordion-pt1 .accordion-row, #tablet-footer-accordion-pt2 .accordion-row {
  color: #fff;
  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
  padding: 20px 0;
  font-weight: bold;
}

#mobile-footer-accordion .accordion-row.line-secondary, #tablet-footer-accordion-pt1 .accordion-row.line-secondary, #tablet-footer-accordion-pt2 .accordion-row.line-secondary {
  border-top: none;
  border-bottom: 1px solid #3f8a93;
}

#mobile-footer-accordion .accordion-arrow, #tablet-footer-accordion-pt1 .accordion-arrow, #tablet-footer-accordion-pt2 .accordion-arrow {
  width: 18px;
  top: 27px;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  #mobile-footer-accordion .accordion-arrow, #tablet-footer-accordion-pt1 .accordion-arrow, #tablet-footer-accordion-pt2 .accordion-arrow {
    width: 33px;
    top: 31px;
  }
}

#mobile-footer-accordion .accordion, #tablet-footer-accordion-pt1 .accordion, #tablet-footer-accordion-pt2 .accordion {
  border: none;
}

#mobile-footer-accordion .accordion button p, #tablet-footer-accordion-pt1 .accordion button p, #tablet-footer-accordion-pt2 .accordion button p {
  line-height: 100%;
}

/*# sourceMappingURL=index.css.map */
